import { ChevronLeft } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
    const navigate = useNavigate();

    return(
        <div className="flex flex-col h-full">
            <div className="flex flex-row text-5xl justify-center my-5">
                <span className="text-red font-mashan text-rose-600">学</span>
                <span className="ml-2">ForeignPage</span>
            </div>
            <div className="text-2xl p-1">
                Sorry, we couldn't find that page for you.
            </div>
            <div className="justify-center my-5">
                <button className="w-48 py-2 rounded-lg bg-slate-700 drop-shadow-lg justify-center" onClick={() => navigate('/home')}>
                    <div className="flex flex-row justify-center mr-5 my-auto">
                        <ChevronLeft></ChevronLeft>
                        <span className="w-auto">Home</span>
                    </div>
                </button>
            </div>
        </div>
    )
}