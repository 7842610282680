import linkedIn from '../../assets/linkedIn.png';

export default function About() {
    return (
        <div className="w-full h-full flex flex-col p-5 pt-0">
            <div className="text-3xl justify-center my-5">
                About ForeignPage
            </div>
            <div className="text-xl md:w-1/2 w-10/12 mb-4 mx-auto">
                At ForeignPage, we believe language learning should be immersive, enjoyable, and effective. That’s why we’ve created a platform that combines the power of e-books (coming soon) with cutting-edge spaced-repetition technology, helping you learn vocabulary and grammar directly from the stories you love.
            </div>
            <div className="text-xl md:w-1/2 w-10/12 mb-4 mx-auto">
                Our mission is to simplify the journey to fluency by offering intuitive tools and a seamless learning experience. Whether you’re diving into a classic novel, exploring new cultures through contemporary stories, or mastering key vocabulary, ForeignPage makes language learning a natural part of your daily routine.
            </div>
            <div className="text-xl md:w-1/2 w-10/12 mb-4 mx-auto">
                Founded by a software engineer and avid language learner, ForeignPage was built to solve the frustrations many learners face: finding the right resources, navigating complex tools, and staying motivated. We’re here to transform the way you learn, one page at a time.
            </div>
            <div className="text-3xl mt-10 md:w-1/2 w-10/12 mx-auto">
                The Devs
            </div>
            <div className="text-lg md:w-1/2 w-10/12 mx-auto text-left mt-5">
                <div className="text-2xl mr-auto flex flex-row">
                    William Thomas
                    <a href="https://www.linkedin.com/in/william-c-thomas/" target="_blank" rel="noreferrer" className="ml-2 my-auto">
                        <img src={linkedIn} height={20} width={20} alt="LinkedIn logo"></img>
                    </a>
                </div>
                <div className="ml-5">Creator, Software Engineer</div>
            </div>
            {/* <a className="mt-10" href='https://play.google.com/store/apps/details?id=com.xuemobile&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank" rel="noreferrer">
                <img className="mx-auto h-20" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
            </a> */}
        </div>
    )
}