import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

enum Page {
    Home, About, Books
}

export default function Header() {
    const [currentPage, setCurrentPage] = useState<Page>(Page.Home);
    
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const pageMap: Map<String, Page> = new Map([
            ['', Page.Home],
            ['home', Page.Home],
            ['edit-set', Page.Home],
            ['study-set', Page.Home],
            ['import-set', Page.Home],
            ['translation-practice', Page.Home],
            ['about', Page.About]
        ]);
        setCurrentPage(pageMap.get(location.pathname.split('/')[1]) ?? Page.Home);
    }, [location]);

    return(
        <div className="sm:h-16 h-10 w-full bg-slate-900 border-b-[1px] border-b-slate-700 flex flex-row sm:px-16 px-8">
            <div className="flex flex-row sm:text-3xl text-2xl justify-center my-auto sm:mr-16 mr-8">
                <span className="ml-2">ForeignPage</span>
            </div>
            <div onClick={() => navigate('/home')} className={"flex flex-col my-auto sm:mr-5 mr-3 cursor-pointer h-full text-xl" + ((currentPage === Page.Home) ? " font-bold" : "")}>
                <div className="sm:mt-auto sm:mb-2 sm:my-0 my-auto">
                    Home
                </div>
                <div className={"sm:h-1/6 h-0" + ((currentPage === Page.Home) ? " bg-rose-600/[.75]" : "")}></div>
            </div>
            <div onClick={() => navigate('/about')} className={"flex flex-col my-auto sm:mx-5 mx-3 cursor-pointer h-full text-xl" + ((currentPage === Page.About) ? " font-bold" : "")}>
                <div className="sm:mt-auto sm:mb-2 sm:my-0 my-auto">
                    About
                </div>
                <div className={"sm:h-1/6 h-0" + ((currentPage === Page.About) ? " bg-rose-600/[.75]" : "")}></div>
            </div>
            <div className={"flex flex-col my-auto sm:mx-5 mx-3 h-full text-xl" + ((currentPage === Page.Books) ? " font-bold" : "")}>
                <div className="sm:mt-auto sm:mb-0.5 sm:my-0 my-auto sm:text-zinc-300 text-zinc-600">
                    Books
                </div>
                <div className="text-xs text-rose-600 hidden sm:block">Coming Soon!</div>
            </div>
        </div>
    );
}